export const WooCommerceOrderByOptions = [
  { label: "Fecha", value: "date" },
  { label: "Id", value: "id" },
];

export enum WooCommerceOrderStatus {
  any = "any",
  pending = "pending",
  "ywraq-pending" = "prep-pending",
  processing = "processing",
  "on-hold" = "on-hold",
  completed = "completed",
  cancelled = "cancelled",
  refunded = "refunded",
  failed = "failed",
  trash = "trash",
}

export const WooCommerceOrderStatusDic: Record<
  WooCommerceOrderStatus,
  string
> = {
  [WooCommerceOrderStatus.any]: "Todas",
  [WooCommerceOrderStatus.pending]: "Pendiente",
  [WooCommerceOrderStatus["ywraq-pending"]]: "Presupuesto pendiente",
  [WooCommerceOrderStatus.processing]: "Procesando",
  [WooCommerceOrderStatus["on-hold"]]: "En Espera",
  [WooCommerceOrderStatus.completed]: "Completado",
  [WooCommerceOrderStatus.cancelled]: "Cancelado",
  [WooCommerceOrderStatus.refunded]: "Reintegrado",
  [WooCommerceOrderStatus.failed]: "Fallado",
  [WooCommerceOrderStatus.trash]: "Basura",
};

export const WooCommerceOrderStatusColor: Record<
  WooCommerceOrderStatus,
  string
> = {
  [WooCommerceOrderStatus.any]: "#FFF",
  [WooCommerceOrderStatus.pending]: "#FFCE56",
  [WooCommerceOrderStatus["ywraq-pending"]]: "#FFCE56",
  [WooCommerceOrderStatus.processing]: "#36A2EB",
  [WooCommerceOrderStatus["on-hold"]]: "#FFCE56",
  [WooCommerceOrderStatus.completed]: "#4BC0C0",
  [WooCommerceOrderStatus.cancelled]: "#FF6384",
  [WooCommerceOrderStatus.refunded]: "#FF9F40",
  [WooCommerceOrderStatus.failed]: "#FF6384",
  [WooCommerceOrderStatus.trash]: "#9966FF",
};

export const WooCommerceStatusOptions = [
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus.any],
    value: WooCommerceOrderStatus.any,
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus.pending],
    value: WooCommerceOrderStatus.pending,
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus["on-hold"]],
    value: WooCommerceOrderStatus["on-hold"],
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus.completed],
    value: WooCommerceOrderStatus.completed,
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus.cancelled],
    value: WooCommerceOrderStatus.cancelled,
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus.processing],
    value: WooCommerceOrderStatus.processing,
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus.refunded],
    value: WooCommerceOrderStatus.refunded,
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus.failed],
    value: WooCommerceOrderStatus.failed,
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus.trash],
    value: WooCommerceOrderStatus.trash,
  },
  {
    label: WooCommerceOrderStatusDic[WooCommerceOrderStatus["ywraq-pending"]],
    value: WooCommerceOrderStatus["ywraq-pending"],
  },
];

export const WooCommerceOrderOptions = [
  { label: "Descendente", value: "desc" },
  { label: "Ascendente", value: "asc" },
];

export const WooCommerceQuantityOptions = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
