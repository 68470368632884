import _ from "lodash";
import Select from "react-select";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { useContactCore } from "../../../cores/contacts";
import { ContactSourceOptions } from "../../../cores/contacts/config";
import { useMeCore } from "../../../cores/me";
import { InputWembii } from "../InputWembii";
import { PanelWembii } from "../PanelWembii";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_PRIMARY } from "../../../constants/theme";
import { useGlobalCore } from "../../../cores/globals";
import {
  CONTACT_STATES_FORM,
  CONTACT_TAGS_FORM,
} from "../../../constants/modal.constant";
import { ContactSource } from "../../../cores/contacts/enum/ContactSource.enum";
import { ContactStateOptions } from "../../../cores/contacts/interfaces/ContactState";
import { useCompanyCore } from "../../../cores/company";
import { LabelWembii } from "../LabelWembii";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const SearchOptions = [
  { value: "email__p", label: "app.contacts.email" },
  { value: "name__p", label: "app.contacts.name" },
  { value: "reference__p", label: "app.contacts.reference" },
  { value: "tel__p", label: "app.contacts.phone" },
];

const FieldOrderOptions = [
  { value: "email", label: "app.contacts.email" },
  { value: "name", label: "app.contacts.name" },
  { value: "phoneNumber", label: "app.contacts.phone" },
  { value: "createdAt", label: "app.contacts.createdDate" },
];

const OrderOptions = [
  { value: "ASC", label: "app.order.asc" },
  { value: "DESC", label: "app.order.desc" },
];

export const ContactsBar = () => {
  const intl = useIntl();
  const [debounceSearchText, setDebounceSearchText] = useState("");
  const [searchOption, setSearchOption] = useState("email__p");
  const [search, setSearch] = useState("");
  const { users } = useCompanyCore();
  const {
    getContactTags,
    getContactStates,
    setContactFilters,
    contactFilters,
    tags,
    states,
    source,
    customFields,
  } = useContactCore();
  const { company, isAdmin, isReseller, companyRole, user } = useMeCore();
  const { turnOnModal } = useGlobalCore();
  const [selectedTags, setSelectedTags] = useState<
    { value: string; label: string }[]
  >([]);
  const [creationDates, setCreationDates] = useState(["", ""]);

  const defaultOwner = useMemo(() => {
    if (!isAdmin && !isReseller && !companyRole?.owner && !companyRole?.admin) {
      return user?.id;
    } else {
      return "";
    }
  }, [isAdmin, isReseller, companyRole, user]);

  const assigments = useMemo(() => {
    if (!isAdmin && !isReseller && !companyRole?.owner && !companyRole?.admin) {
      return [
        { label: intl.formatMessage({ id: "app.source.all" }), value: "" },
        { label: `${user?.firstName} ${user?.lastName}`, value: user?.id },
      ];
    } else {
      return [
        { label: intl.formatMessage({ id: "app.source.all" }), value: "" },
        ...users.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        })),
      ];
    }
  }, [isAdmin, isReseller, companyRole, users, user, intl]);

  const debouncedSearch = useRef(
    _.debounce((search) => setSearch(search), 1000)
  ).current;

  useEffect(() => {
    setContactFilters({
      page: 1,
      perPage: 10,
      sources: contactFilters.sources,
      owner: contactFilters.owner,
      [searchOption]: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchOption, setContactFilters]);

  useEffect(() => {
    setContactFilters({
      ...contactFilters,
      owner: defaultOwner,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOwner, setContactFilters]);

  useEffect(() => {
    debouncedSearch(debounceSearchText);
  }, [debounceSearchText, debouncedSearch]);

  useEffect(() => {
    if (
      [
        ...Object.values(ContactSource),
        ...tags.map((tag) => tag.nameId),
      ].includes(source)
    ) {
      if (
        !contactFilters.sources ||
        (contactFilters.sources &&
          (contactFilters.sources.length === 0 ||
            contactFilters.sources.length > 1 ||
            (contactFilters.sources.length === 1 &&
              contactFilters.sources[0] !== source)))
      ) {
        setContactFilters({ ...contactFilters, sources: [source] });
      }
    }
  }, [source, tags, contactFilters, setContactFilters]);

  const onOpenContactTags = useCallback(() => {
    getContactTags(company?.id!);
    turnOnModal(CONTACT_TAGS_FORM, true);
  }, [turnOnModal, getContactTags, company]);

  const onOpenContactStates = useCallback(() => {
    getContactStates(company?.id!);
    turnOnModal(CONTACT_STATES_FORM, true);
  }, [turnOnModal, getContactStates, company]);

  const tagsValues = useMemo(
    () => [
      ...ContactSourceOptions.map((source) => ({
        value: source.value,
        label: intl.formatMessage({ id: source.label }),
      })),
      ...tags.map((tag) => ({ value: tag.nameId, label: tag.name })),
    ],
    [tags, intl]
  );

  const changeFilters = useCallback(
    (field) => {
      setContactFilters({ ...contactFilters, ...field });
    },
    [contactFilters, setContactFilters]
  );

  const statesValues = useMemo(
    () => [
      ...ContactStateOptions.map((state) => ({
        value: state.value,
        label: intl.formatMessage({ id: state.label }),
      })),
      ...states.map((state) => ({ value: state.id, label: state.name })),
    ],
    [states, intl]
  );
  useEffect(() => {
    return () => {
      setSearchOption("");
      setContactFilters({
        page: 1,
        perPage: 10,
      });
      setDebounceSearchText("");
      setSearch("");
      setSelectedTags([]);
      setCreationDates(["", ""]);
    };
  }, []);

  const searchOptions = useMemo(
    () => [
      ...SearchOptions.map((option) => ({
        value: option.value,
        label: intl.formatMessage({ id: option.label }),
      })),
      ...customFields.map((field) => ({ value: field.id, label: field.name })),
    ],
    [customFields, intl]
  );

  const setTags = useCallback(
    (value) => {
      let tags = [];
      if (value) {
        tags = value.map(
          (option: { value: string; label: string }) => option.value
        );
        setSelectedTags(value);
      } else {
        setSelectedTags([]);
      }

      setContactFilters({
        ...contactFilters,
        sources: tags,
      });
    },
    [contactFilters, setContactFilters]
  );

  const onChangeCreationDate = useCallback(
    (dates) => {
      setCreationDates(dates);
      setContactFilters({ ...contactFilters, creationDate: dates });
    },
    [contactFilters, setContactFilters]
  );

  return (
    <PanelWembii className="w-100 px-4 py-3 my-3 mb-5">
      <h2 className="h2 text-center mb-4">
        {intl.formatMessage({ id: "app.crmBusiness.filters" })}
      </h2>
      <Row>
        <Col xs="12" lg="12" className="my-2 ">
          <InputWembii
            label={intl.formatMessage({ id: "app.contacts.search" })}
            placeholder={intl.formatMessage({ id: "app.contacts.search1" })}
            type="text"
            mb={false}
            onChange={(v) => setDebounceSearchText(v)}
            value={debounceSearchText}
            style={{ borderRadius: "0px 10px 10px 0px" }}
            prepend={
              <InputWembii
                type="select"
                onChange={(v) => setSearchOption(v)}
                value={searchOption}
                mb={false}
                style={{
                  borderRadius: "10px 0px 0px 10px",
                  maxWidth: "125px",
                }}
              >
                {searchOptions.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </InputWembii>
            }
          />
        </Col>
        <Col xs="12" lg="12" className="my-2 ">
          <div className="d-flex align-items-end">
            <InputWembii
              label={intl.formatMessage({ id: "app.contacts.state" })}
              type="select"
              className="w-100"
              mb={false}
              onChange={(state) => changeFilters({ state })}
              value={contactFilters.state || ""}
            >
              <option value="">
                {intl.formatMessage({ id: "app.source.all" })}
              </option>
              {statesValues.map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </InputWembii>
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              size="2x"
              className="m-2 pointer"
              color={COLOR_PRIMARY}
              onClick={onOpenContactStates}
            />
          </div>
        </Col>
        <Col xs="12" lg="12" className="my-2 ">
          <InputWembii
            type="select"
            label={intl.formatMessage({ id: "app.contacts.owners" })}
            onChange={(owner) =>
              setContactFilters({ ...contactFilters, owner })
            }
            value={contactFilters.owner}
            mb={false}
          >
            {assigments.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </InputWembii>
        </Col>
        <Col xs="12" lg="12">
          <div className="d-flex align-items-end my-2 ">
            <InputWembii
              label={intl.formatMessage({ id: "app.contacts.order" })}
              type="select"
              className="mx-1"
              mb={false}
              onChange={(fieldOrder) => changeFilters({ fieldOrder })}
              value={contactFilters.fieldOrder || "createdAt"}
            >
              {FieldOrderOptions.map(({ label, value }) => (
                <option key={label} value={value}>
                  {intl.formatMessage({ id: label })}
                </option>
              ))}
            </InputWembii>
            <InputWembii
              label=""
              type="select"
              className="mx-1"
              mb={false}
              onChange={(order) => changeFilters({ order })}
              value={contactFilters.order || "DESC"}
            >
              {OrderOptions.map(({ label, value }) => (
                <option key={label} value={value}>
                  {intl.formatMessage({ id: label })}
                </option>
              ))}
            </InputWembii>
          </div>
        </Col>
        <Col xs="12" lg="12">
          <div className="d-flex my-4  align-items-end ">
            <FormGroup className="d-flex flex-column position-relative w-100 m-0">
              <LabelWembii>
                {intl.formatMessage({ id: "app.contacts.origin" })}
              </LabelWembii>
              <Select
                isMulti
                id="tag"
                name="tags"
                options={tagsValues}
                value={selectedTags}
                className=""
                onChange={setTags}
                placeholder={intl.formatMessage({ id: "app.source.all" })}
                classNamePrefix="select"
              />
            </FormGroup>
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              size="2x"
              className="m-2 pointer"
              color={COLOR_PRIMARY}
              onClick={onOpenContactTags}
            />
          </div>
        </Col>
        <Col xs="12" lg="12">
          <div className="h-100 d-flex flex-column my-2 justify-content-center align-items-start">
            <LabelWembii>
              {intl.formatMessage({ id: "app.contacts.createdDate" })}
            </LabelWembii>
            <DateRangePicker
              onChange={onChangeCreationDate}
              value={creationDates}
            />
          </div>
        </Col>
      </Row>
    </PanelWembii>
  );
};
